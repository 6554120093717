import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { withTranslation, useTranslation } from 'react-i18next';

const images = [
    require('./../../images/about/Scalp-micropigmentation-reviews.jpg'),
    require('./../../images/about/Scalp-micropigmentation-before-and-after.jpg'),
    require('./../../images/about/Scalp-micropigmentation-for-alopecia.jpg'),
    require('./../../images/about/Scalp-micropigmentation-for-hair-loss.jpg'),
    require('./../../images/about/Scalp-micropigmentation-for-thinning-hair.jpg'),
]

class About2 extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        if (this.videoRef.current) {
            this.videoRef.current.muted = true;
        }
    }
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: false,
        };
        return (
            <>
                <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                <div className="col-lg-5 col-md-6">
                    <div className="m-about m-l50 m-r50">
                        <OwlCarousel className="owl-carousel about-us-carousel owl-btn-bottom-right" {...options}>
                            <div className="video-container">
                                <video 
            ref={this.videoRef}
            className="rev-slidebg" 
            controls 
            autoPlay 
            loop
        >
                                    <source src={require('./../../videos/best-scalp-micropigmentation-capillaire-montreal-pierrefonds.mov').default} type="video/mp4" />
                                    {this.props.t('browserSupportText')}
                                </video>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-uppercase text-black p-t30">
                                            <span className="font-30 font-weight-300">{this.props.t('aboutUsTitle')}</span>
                                            <h2 className="font-40">{this.props.t('ourMissionStatement')}</h2>
                                            <p><b>{this.props.t('throughHighQuality')}</b></p>
                                            <p className="text-lowercase">{this.props.t('dedicatedOffering')}</p>
                                            <p className="text-lowercase">{this.props.t('combiningTechniques')}</p>
                                                <a href="tel:+14385421696" className="site-button outline  black button-app m-r15 m-b15"><strong className="text-center">438.542.1696</strong>  <strong className="text-center">{this.props.t('askQuestionsBtn')}</strong></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default withTranslation()(About2);
