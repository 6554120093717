import React, { useState } from 'react';
import GoogleMaps from "simple-react-google-maps";
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

function ContactUs() {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const history = useHistory();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/.netlify/functions/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    subject: 'Contact Form Submission',
                    message: formData.message
                }),
            });
            if (response.ok) {
                console.log('Email sent successfully!');
                setFormData({ name: '', email: '', message: '' });
                history.push('/contact-success');
            } else {
                console.error('Error sending email.');
            }
        } catch (error) {
            setIsSuccess(false);
            setIsError(true);
            console.error('There was an error sending the email.', error);
        }
    };

    return (
        <>
            <Header2 />
            <div className="page-content">
                <Banner title={t("bannerTitle")} pagename={t("contactUs")} bgimage={bnrimg.default} />
                <div className="section-full p-tb80 tm-shortcode-wrap">
                    <div className="container">
                        <div className="section-head text-left text-black">
                            <h2 className="text-uppercase font-36">{t("findUsTitle")}</h2>
                            <div className="wt-separator-outer">
                                <div className="wt-separator bg-black" />
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="wt-box">
                                {isSuccess && (
                                    <div className="alert alert-success">
                                        <strong><i className="fa fa-thumbs-o-up" /> {t("successMessage")}</strong>
                                    </div>
                                )}
                                {isError && (
                                    <div className="alert alert-danger">
                                        <strong><i className="fa fa-remove" /> {t("errorMessage")}</strong>
                                    </div>
                                )}
                                <form className="contact-form cons-contact-form" onSubmit={handleSubmit}>
                                    <div className="contact-one p-a40 p-r150">
                                        <div className="form-group">
                                            <input name="name" type="text" value={formData.name} onChange={handleChange} required className="form-control" placeholder="Name" />
                                        </div>
                                        <div className="form-group">
                                            <input name="email" type="email" value={formData.email} onChange={handleChange} className="form-control" required placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" value={formData.message} onChange={handleChange} className="form-control" required placeholder="Your Message"></textarea>
                                        </div>
                                        <button name="submit" type="submit" value="Submit" className="site-button black radius-no text-uppercase">
                                            <span className="font-12 letter-spacing-5">{t("submitButton")}</span>
                                        </button>
                                        <div className="contact-info bg-black text-white p-a30">
                                            <div className="wt-icon-box-wraper left p-b30">
                                                <div className="icon-sm">
                                                    <i className="iconmoon-smartphone-1" />
                                                </div>
                                                <div className="icon-content text-white ">
                                                    <h5 className="m-t0 text-uppercase">{t("phoneLabel")}</h5>
                                                    <p><a href="tel:+14385421696">438.542.1696</a></p>
                                                </div>
                                            </div>
                <div className="wt-icon-box-wraper left p-b30">
                    <div className="icon-sm"><i className="iconmoon-email" /></div>
                    <div className="icon-content text-white">
                        <h5 className="m-t0  text-uppercase">Email address</h5>
                        <p>info@scalpgomicro.ca</p>
                    </div>
                </div>
                <div className="wt-icon-box-wraper left">
                    <div className="icon-sm"><i className="iconmoon-travel" /></div>
                    <div className="icon-content text-white">
                        <h5 className="m-t0  text-uppercase">Address info</h5>
                        <p>Pierrefonds 4493 Bd Westpark QC H9A 2X8</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

                            </div>
                        </div>
                    </div>
                    <div className="section-full">
                        <div className="gmap-outline">
                       

                                <GoogleMaps
      apiKey={"AIzaSyAXhPYiCs6Ex0EEztJiFwhK2MZ9kbt9buc"}
      style={{ height: "460px", width: "100%" }}
      zoom={12}
      center={{ lat: 45.50297890082292, lng: -73.82760890799995 }} // Use the provided latitude and longitude
      markers={{ lat: 45.50297890082292, lng: -73.82760890799995 }} // Use the same latitude and longitude for the marker
    />
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };

export default ContactUs;
