import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/scalp-micropigmentation-west-island-montreal.png'),
    require('./../../images/gallery/best-scalp-micropigmentation-pierrefonds.png'),
    require('./../../images/gallery/solution-moderne-pour-la-perte-des-cheveux.jpg'),
    require('./../../images/gallery/scalp-micropigmentation-clinic-montreal-nord.jpg'),
    require('./../../images/gallery/scalp-micropigmentation-laval.jpg')
];

function About(props) {
    const { t } = useTranslation();

    const options = {
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 3000,
        nav: false,
        dots: true,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            767: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <>
            <div className="section-full p-t90 bg-gray tm-welcome-warp">
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-5 col-sm-12 text-uppercase text-black">
                                <span className="font-30 font-weight-300">{t("welcomeTitle")}</span>
                                <h2 className="font-40">
                                    {t("aboutHeading1")} <span className={props.colorclass1}>{t("aboutHeading2")}</span> <span className={props.colorclass2}>{t("aboutHeading3")}</span>
                                </h2>
                                <p>{t("location")}</p>
                                <p>{t("aboutDescription")}</p>
                                

                                <a href="tel:+14385421696" className="btn-half site-button button-lg" title={t("makeAChoice")} rel="bookmark" style={{color: '#fff'}}>{t("call")}: +1 438.542.1696</a>

                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="m-carousel-1 m-l100">
                                    <OwlCarousel className="owl-carousel home-carousel-1 owl-btn-vertical-center" {...options}>
                                        {images.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="ow-img wt-img-effect zoom-slow">
                                                    <img src={item.default} alt="" />
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>{t("certified")}</strong>
                            <span className="text-black">{t("micropigmentationCapillaire")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
