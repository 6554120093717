import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import RelatedProjects from './../Segments/RelatedProjects';
import BlogComments from './../Segments/BlogComments';

var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

const images = [
    { image: require('./../../images/Home/chemo.jpg') },
    { image: require('./../../images/Home/chemo-2.jpg') },
    { image: require('./../../images/Home/calvitie.jpg') },
    { image: require('./../../images/Home/calvitie-2.jpg') },
]

class PostImage extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Scalp Micropigmentation (SMP): A Hopeful Intervention Post-Chemotherapy Treatment" pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Scalp Micropigmentation (SMP): A Hopeful Intervention Post-Chemotherapy Treatment</span></h2>
                                    </div>
                                    <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div>
                                    <div className="wt-post-text">
                                        <p>Coping with hair loss resulting from chemotherapy treatment can be emotionally distressing for many. It's an unwelcome reminder of their ongoing battle against cancer. The good news is that hair restoration advancements have made it possible for people to regain their confidence and live a fuller life after chemotherapy. Scalp Micropigmentation, also referred to as 'traitement de micropigmentation capillaire', has emerged as a promising solution to address the aftermath of chemotherapy-induced hair loss.</p>

<h3>Understanding Scalp Micropigmentation (SMP)</h3>

<p>Scalp Micropigmentation is a non-invasive hair restoration procedure that involves the use of micro-needles to deposit pigment into the scalp, creating the appearance of tiny hair follicles or short hair. SMP can be applied to specific hair loss areas or throughout the entire scalp for a more uniform look. It is a popular choice for individuals experiencing hair loss from various causes, including the side effects of chemotherapy treatment.</p>

<h3>The Role of Scalp Micropigmentation Post-Chemotherapy</h3>

<p>Undergoing chemotherapy can be a challenging journey. One of the most apparent side effects of this treatment is hair loss, which can be a major blow to an individual's self-esteem and emotional well-being. Here, Scalp Micropigmentation comes into play:</p>

<ul>
<li>A Confidence Booster: SMP helps to restore the appearance of natural hair, which can boost the individual's self-esteem and confidence, empowering them to focus on their recovery with a positive mindset.</li>

<li>Immediate Results: Post-chemotherapy hair regrowth can take time. However, SMP offers immediate results, providing a realistic hair appearance soon after the treatment sessions.</li>

<li>Versatile and Customizable: Whether you've lost hair in patches or experienced complete baldness, SMP treatments can be tailored to match your specific needs.</li>

<li>Safe and Non-invasive: SMP is non-surgical and typically has minimal side effects, making it a safe option for individuals recovering from chemotherapy.</li></ul>


<h3>Who is an Ideal Candidate for SMP Post-Chemotherapy?</h3>

<p>SMP is suitable for anyone who has experienced hair loss due to chemotherapy and wants to improve their hair's appearance. However, it is important to consult with a healthcare provider before beginning the treatment to ensure it aligns with your recovery and overall health condition.</p>

<h3>Conclusion: A New Lease on Life with SMP</h3>

<p>For those navigating the challenging path of chemotherapy and dealing with its side effects, Scalp Micropigmentation can offer a beacon of hope. This innovative 'traitement de micropigmentation capillaire' can help restore not only your appearance but also your confidence and sense of normalcy. Reach out to a certified SMP practitioner today to discuss your options and take the first step toward reclaiming your image and identity post-chemotherapy. Your journey to recovery deserves to be walked with self-assurance and positivity.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../images/Home/best-version-of-yourself.jpg").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Scalp Micropigmentation is not merely a cosmetic procedure; it's a life-altering solution. It's about reclaiming your confidence and self-image by presenting to the world the best version of yourself.
                                                    <div className="p-t15"><p> – Dr. William Rassman, Renowned Hair Restoration Surgeon.</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                        <p>If you've been searching for a definitive solution to hair loss, look no further. Scalp Micropigmentation offers a cutting-edge, long-lasting, and effective treatment that's transforming lives worldwide. It's time to turn the page and start a new chapter in your life – one filled with confidence and self-assurance. Don't let hair loss hold you back any longer. Schedule a consultation with a Scalp Micropigmentation specialist today and embark on the journey to restore not only your hair but your overall self-esteem and quality of life. Start your SMP journey now! Contact Us Today: +1 438.542.1696
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 col-sm-6">
                                            <div className="widget bg-white  widget_tag_cloud">
                                                <h4 className="tagcloud text-uppercase">Tags</h4>
                                                <div className="tagcloud">
                                                    <NavLink to={"/aboutus"}>Scalp Micropigmentation</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Restoration</NavLink>
                                                    <NavLink to={"/aboutus"}>Non-surgical Treatment</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Loss Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>SMP Procedure</NavLink>
                                                    <NavLink to={"/aboutus"}>Cost-effective</NavLink>
                                                    <NavLink to={"/aboutus"}>Baldness Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>Hairline Enhancement</NavLink>
                                                    <NavLink to={"/aboutus"}>Self-Esteem Boost</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="wt-box">
                                                <div className="row  p-lr15">
                                                    <h4 className="tagcloud text-uppercase">Share this Post:</h4>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" /></li>
                                                            <li><a target="_blank" href="https://twitter.com" className="fa fa-twitter" /></li>
                                                            <li><a target="_blank" href="https://in.linkedin.com" className="fa fa-linkedin" /></li>
                                                            <li><a target="_blank" href="https://rss.com" className="fa fa-rss" /></li>
                                                            <li><a target="_blank" href="https://www.youtube.com/" className="fa fa-youtube" /></li>
                                                            <li><a target="_blank" href="https://www.instagram.com/" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                 {/*<BlogComments/>*/}
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default PostImage;