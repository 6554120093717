import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { withTranslation, useTranslation } from 'react-i18next';

const logos = [
    { image: require('./../../images/client-logo/w1.png') },
    { image: require('./../../images/client-logo/w2.png') },
    { image: require('./../../images/client-logo/w3.png') },
    { image: require('./../../images/client-logo/w4.png') },
    { image: require('./../../images/client-logo/w5.png') },
    { image: require('./../../images/client-logo/w6.png') },
]

class ClientsLogo extends React.Component {
    render() {
        const options = {
            loop: true,
            margin: 10,
            nav: true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 2
                },
                480: {
                    items: 3
                },
                767: {
                    items: 4
                },
                1000: {
                    items: 5
                }
            }
        };
        return (
            <>
                <div id="contact" className="section-full p-tb40 bg-black square_shape4 tm-client-wrap">
                    <div className="container">
                        <div className="section-content">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="text-left">
                                            <h2 className="text-uppercase font-36 text-white">{this.props.t('contactUsTitle')}</h2>
                                            <div className="wt-separator-outer">
                                                <div className="wt-separator bg-white" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-sm-8 m-b30">
                                        <div className="wt-icon-box-wraper bx-style-1 p-a20 left bg-white">
                                            <div className="wt-icon-box-xs text-primary bg-white radius bdr-2 ">
                                                <span className="icon-cell text-primary"><i className="fa fa-google-wallet" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h5 className="wt-tilte text-uppercase letter-spacing-2 font-weight-800">{this.props.t('availabilityInfo')}</h5>
                                                <p>{this.props.t('addressInfo')} <br /> 
                                                <a href="tel:+14385421696">438.542.1696</a> <br /> <a href="mailto:info@scalpgomicro.ca">info@scalpgomicro.ca</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default withTranslation()(ClientsLogo);
